import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'list',
    loadChildren: './list/list.module#ListPageModule'
  },
  { path: 'respuestas/:id', loadChildren: './respuestas/respuestas.module#RespuestasPageModule' },
  { path: 'resultados/:id', loadChildren: './resultados/resultados.module#ResultadosPageModule' },
  { path: 'admin-preguntas', loadChildren: './admin-preguntas/admin-preguntas.module#AdminPreguntasPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
